import { getAuth } from "firebase/auth";
import React, { useState, useEffect, useContext } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { firestore } from './firebase-config';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { AuthContext } from './AuthContext'; // Import AuthContext
const VerseRevision = ({ userRank }) => {
  const [sections, setSections] = useState([]);
  const [currentSection, setCurrentSection] = useState('');
  const [notes, setNotes] = useState([]);
  const [newNoteTitle, setNewNoteTitle] = useState('');
  const [newNoteWeight, setNewNoteWeight] = useState('');
  const [newNoteContent, setNewNoteContent] = useState('');
  const [newSectionName, setNewSectionName] = useState('');
  const [visibleNoteId, setVisibleNoteId] = useState(null);
  const [confirmDeleteId, setConfirmDeleteId] = useState(null);
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [editTitle, setEditTitle] = useState('');
  const [editWeight, setEditWeight] = useState('');
  const [editContent, setEditContent] = useState('');
   const [isAuthorized, setIsAuthorized] = useState(false); // state to check if the user is authorized
   const [isRandomized, setIsRandomized] = useState(false); // New state for tracking randomization
 const { loggedInUser } = useContext(AuthContext);
  useEffect(() => {
    const auth = getAuth();

    // Check if the user is authenticated and if their display name is 'Aaron'
    if (loggedInUser && loggedInUser.username === 'Aaron') {
      setIsAuthorized(true); // Allow access to the page
      fetchSections();
    } else {
      setIsAuthorized(false); // Deny access
    }
  }, []);

  useEffect(() => {
    fetchSections();
  }, []);

  useEffect(() => {
    if (currentSection) {
      fetchNotes();
    }
  }, [currentSection]);

  const fetchSections = async () => {
    try {
      const auth = getAuth();
      if (!auth.currentUser) {
        console.error("User is not authenticated");
        return;
      }

      const sectionsRef = collection(firestore, 'sections');
      const sectionsSnapshot = await getDocs(sectionsRef);
      const sectionsData = sectionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sort sections alphabetically by their `id` (assuming `id` is the name of the section)
      const sortedSections = sectionsData.sort((a, b) => a.name.localeCompare(b.name));

      setSections(sortedSections);
      if (sortedSections.length > 0) {
        setCurrentSection(sortedSections[0].name);
      }
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
};

const [noteCount, setNoteCount] = useState(0); // Add state for note count

const fetchNotes = async () => {
  try {
    const notesRef = collection(firestore, `sections/${currentSection}/notes`);
    const notesSnapshot = await getDocs(notesRef);
    const notesData = notesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      stared: doc.data().stared ?? false,
      weight: doc.data().weight ? parseFloat(doc.data().weight) : 0,
    }));

    let sortedNotes = notesData;
      if (!isRandomized) {
        // Original sorting logic
        sortedNotes = notesData.sort((a, b) => {
          if (b.stared !== a.stared) return b.stared ? 1 : -1;
          return a.weight - b.weight;
        });
      } else {
        // Randomize the array
        sortedNotes = [...notesData].sort(() => Math.random() - 0.5);
      }

    setNotes(sortedNotes);

    setNoteCount(notesData.length); // Update the note count
  } catch (error) {
    console.error('Error fetching notes:', error);
  }
};

  const addNote = async () => {
    if (newNoteTitle.trim() && newNoteContent.trim() && currentSection) {
      try {
        const notesRef = collection(firestore, `sections/${currentSection}/notes`);
        await addDoc(notesRef, {
          title: newNoteTitle,
          content: newNoteContent,
          weight: newNoteWeight,
          stared: false,
        });
        setNewNoteTitle('');
        setNewNoteContent('');
        setNewNoteWeight('');
        fetchNotes();
      } catch (error) {
        console.error('Error adding note:', error);
      }
    }
  };

  const deleteNote = async (noteId) => {
    try {
      const noteRef = doc(firestore, `sections/${currentSection}/notes`, noteId);
      await deleteDoc(noteRef);
      setConfirmDeleteId(null);
      fetchNotes();
    } catch (error) {
      console.error('Error deleting note:', error);
    }
  };

  // New function to toggle randomization
  const toggleRandomize = () => {
    setIsRandomized(!isRandomized);
    fetchNotes(); // Refresh notes with new order
  };

  const toggleContent = (noteId) => {
    setVisibleNoteId(visibleNoteId === noteId ? null : noteId);
  };

  const toggleStar = async (noteId, currentStatus) => {
    try {
      const noteRef = doc(firestore, `sections/${currentSection}/notes`, noteId);
      await updateDoc(noteRef, { stared: !currentStatus });
      fetchNotes();
    } catch (error) {
      console.error('Error updating star status:', error);
    }
  };

  const swapAllTitlesAndContents = async () => {
    try {
      const swapPromises = notes.map((note) => {
        const noteRef = doc(firestore, `sections/${currentSection}/notes`, note.id);
        return updateDoc(noteRef, { title: note.content, content: note.title });
      });
      await Promise.all(swapPromises);
      fetchNotes();
    } catch (error) {
      console.error('Error swapping titles and contents:', error);
    }
  };

  const addSection = async () => {
    if (newSectionName.trim()) {
      try {
        const sectionsRef = collection(firestore, 'sections');
        const sectionDoc = await addDoc(sectionsRef, { name: newSectionName });
        setSections([...sections, { id: sectionDoc.id, name: newSectionName }]);
        setNewSectionName('');
        setCurrentSection(sectionDoc.id);
      } catch (error) {
        console.error('Error adding section:', error);
      }
    }
  };

  const editNote = async (noteId) => {
    if (editTitle.trim() && editContent.trim() && editWeight) {
      try {
        const noteRef = doc(firestore, `sections/${currentSection}/notes`, noteId);
        await updateDoc(noteRef, { title: editTitle, weight: editWeight, content: editContent });
        setEditingNoteId(null);
        setEditTitle('');
        setEditWeight('');
        setEditContent('');
        fetchNotes();
      } catch (error) {
        console.error('Error editing note:', error);
      }
    }
  };

  if (!isAuthorized) {
    return <div style={{ color: 'red', textAlign: 'center', paddingTop: '50px' }}>You are not authorized to access this page.</div>;
  }

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Verse Revision</h2>

      <div style={styles.sectionNav}>
        {sections.map((section) => (
          <button
            key={section.id}
            onClick={() => setCurrentSection(section.id)}
            style={{
              ...styles.sectionButton,
              backgroundColor: currentSection === section.id ? '#FFA500' : '#444',
            }}
          >
            {section.name}
          </button>
        ))}
      </div>

      <div style={styles.addSectionContainer}>
      <h3 style={{ color: '#fff', textAlign: 'center' }}>Notes in Section: {noteCount}</h3>
        <input
          type="text"
          placeholder="New Section Name"
          value={newSectionName}
          onChange={(e) => setNewSectionName(e.target.value)}
          style={styles.input}
        />
        <button onClick={addSection} style={styles.button}>Add Section</button>
      </div>

      <input
        type="text"
        placeholder="Note Title"
        value={newNoteTitle}
        onChange={(e) => setNewNoteTitle(e.target.value)}
        style={styles.noteTitleInput}
      />
      <ReactQuill value={newNoteContent} onChange={setNewNoteContent} style={styles.quill} />
      <input
        type="text"
        placeholder="Note Weight"
        value={newNoteWeight}
        onChange={(e) => setNewNoteWeight(e.target.value)}
        style={styles.noteWeightInput}
      />
      <button onClick={addNote} style={styles.button}>Add Note</button>
      <button onClick={swapAllTitlesAndContents} style={styles.swapAllButton}>Swap All</button>
      <button 
        onClick={toggleRandomize} 
        style={{
          ...styles.button,
          backgroundColor: isRandomized ? '#FF4444' : '#4CAF50'
        }}
      >
        {isRandomized ? 'Unrandomize' : 'Randomize'}
      </button>
      <ul style={styles.list}>
        {notes.map((note) => (
          <li key={note.id} style={{ ...styles.listItem, backgroundColor: note.stared ? '#333' : 'transparent' }}>
            {confirmDeleteId === note.id ? (
              <div style={styles.confirmation}>
                <button onClick={() => deleteNote(note.id)} style={styles.confirmButton}>Yes</button>
                <button onClick={() => setConfirmDeleteId(null)} style={styles.cancelButton}>No</button>
              </div>
            ) : (
              <>
                <button onClick={() => {
                    setEditingNoteId(note.id);
                    setEditTitle(note.title);
                    setEditWeight(note.weight);
                    setEditContent(note.content);
                  }} style={styles.editButton}>Edit</button>
                <button onClick={() => setConfirmDeleteId(note.id)} style={styles.deleteButton}>Delete ↓</button>
                <button onClick={() => toggleStar(note.id, note.stared)} style={styles.starButton}>
                  {note.stared ? '★' : '☆'}
                </button>
              </>
            )}
            <div style={styles.noteRow}>
              <p onClick={() => toggleContent(note.id)} style={{ ...styles.noteTitle, cursor: 'pointer' }}>{note.title}</p>
            </div>
            {visibleNoteId === note.id && <div style={styles.noteContent} dangerouslySetInnerHTML={{ __html: note.content }} />}
            {editingNoteId === note.id && (
              <div style={styles.editForm}>
                <input
                  type="text"
                  value={editTitle}
                  onChange={(e) => setEditTitle(e.target.value)}
                  style={styles.input}
                />
                <input
                  type="text"
                  value={editWeight}
                  onChange={(e) => setEditWeight(e.target.value)}
                  style={styles.input}
                />
                <ReactQuill value={editContent} onChange={setEditContent} style={styles.quill} />
                <button onClick={() => editNote(note.id)} style={styles.button}>Save Changes</button>
                <button onClick={() => setEditingNoteId(null)} style={styles.button}>Cancel</button>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const styles = {
  container: { maxWidth: '600px', margin: '0 auto', padding: '20px', borderRadius: '8px', backgroundColor: '#202020' },
  header: { textAlign: 'center', color: '#fff' },
  input: { width: '96.6%', padding: '10px', marginBottom: '10px', borderRadius: '4px', border: '1px solid #ddd' },
  quill: {   marginBottom: '10px' },
  textarea: { width: '97%', padding: '10px', height: '100px', marginBottom: '10px', borderRadius: '4px', border: '1px solid #ddd' },
  button: { width: '100%', padding: '10px', backgroundColor: '#007BFF', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer', marginBottom: '10px' },
  list: { listStyleType: 'none', padding: '0' },
  listItem: { marginBottom: '15px', padding: '10px', borderRadius: '4px' },
  noteRow: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  noteTitle: { margin: '0', backgroundColor: '#444', color: '#fff', padding: '10px', flex: 1 },
  noteContent: { margin: '0', padding: '10px', backgroundColor: '#313131', color: '#fff' },
  deleteButton: { padding: '5px 10px', backgroundColor: 'transparent', color: 'red', border: 'none', borderRadius: '4px', cursor: 'pointer' },
  starButton: { padding: '5px 10px', backgroundColor: 'transparent', color: '#FFD700', border: 'none', borderRadius: '4px', cursor: 'pointer', fontSize: '16px' },
  confirmation: { display: 'flex', gap: '10px', marginBottom: '10px' },
  confirmButton: { padding: '5px 10px', backgroundColor: 'green', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' },
  cancelButton: { padding: '5px 10px', backgroundColor: 'gray', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' },
  sectionNav: { display: 'flex', flexDirection: 'column', gap: '5px', marginBottom: '10px', maxHeight: '200px', overflowY: 'auto' },
  sectionButton: { padding: '8px', borderRadius: '4px', cursor: 'pointer', color: '#fff' },
swapAllButton: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#FFA500',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginBottom: '20px',
  },
  noteTitleInput: {
    width: '96.6%',
    padding: '10px',
    border: 'none',
    borderRadius: '4px',
    marginTop: '25px',
    marginBottom: '10px',
  },
  // editButton: { padding: '5px 10px', backgroundColor: '#FFD700', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' },
  editForm: { marginTop: '10px' },
};

export default VerseRevision;
